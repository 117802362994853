import React, { Component }                     from 'react';
import { NavItem, SideNav,  Button }            from 'react-materialize';
import { Link as ScrollLink }                   from "react-scroll"; 
import { /*Link as BaseLink, */ withRouter }         from 'react-router-dom';

import logo                                     from '../../img/logo.svg';


function InnerLink(props) {    
    return(
        <ScrollLink className={ props.className } to={ props.link} spy={true} smooth={true} offset={ -96 } duration= {1500}>{ props.title }</ScrollLink>
    );
}
function PageLink(props) {
    return(
        <NavItem className={ props.className } href={ props.link } >{ props.title }</NavItem>
    );
}
class Navbar extends Component {
    state= {
        links_home: null,
        links_home_presentation: null,
        links_home_lots: null,
        links_home_participez: null,
        links_home_gagnants: null,
        links_home_participations: null
    }
     
    componentDidMount() {
        if(this.props.location.pathname === '/') {
            this.setState({
            links_home: <InnerLink className="brand-logo left" link='app_main' title={ <img width="75px" height="94px" alt={ process.env.REACT_APP_PRESENTATION_TITLE } src={logo} className='img-responsive' />} />,
            links_home_presentation: <li><InnerLink link='app_presentation' title={ process.env.REACT_APP_MENU_PRESENTATION } /></li>,
            links_home_lots: <li><InnerLink link='app_lots' title={ process.env.REACT_APP_LOTS } /></li>,
            links_home_gagnants: <li><InnerLink link='app_gagnants' title="La remise de prix" /></li>,
            links_home_participations: <li><InnerLink link='app_participations' title={ process.env.REACT_APP_MENU_PARTICIPATIONS } /></li>
        })
            
        } else { 
            this.setState({
                links_home: <NavItem href={ process.env.REACT_APP_PUBLIC_URL } className="brand-logo left"><img width="75px" height="94px" alt={ process.env.REACT_APP_PRESENTATION_TITLE } src={logo} className='img-responsive' /></NavItem>,
                links_home_presentation:  <PageLink link={process.env.REACT_APP_PUBLIC_URL} title={ process.env.REACT_APP_MENU_PRESENTATION } />,
                links_home_lots: <PageLink link={process.env.REACT_APP_PUBLIC_URL+'#app_lots'} title={ process.env.REACT_APP_LOTS } />,
                links_home_gagnants: <PageLink link={process.env.REACT_APP_PUBLIC_URL+'#app_gagnants'} title="La remise de prix" />,
                links_home_participations: <PageLink link={process.env.REACT_APP_PUBLIC_URL+'#app_participations'} title={ process.env.REACT_APP_MENU_PARTICIPATIONS } />
            })
        }
    }
    handlClick(e) {
        e.preventDefault();
    }

    render() { 
        return (
                <div className="navbar-fixed">
                    <nav className="nav_main">
                        <div className="nav-wrapper">
                            { this.state.links_home }
                            <div className="nav_mobile hide-on-large-only">
                                <SideNav
                                trigger={<Button></Button>}
                                options={{ closeOnClick: true }}
                                >
                                    { this.state.links_home_presentation }
                                    { this.state.links_home_gagnants }
                                    { this.state.links_home_participations }
                                    { this.state.links_home_lots }
                                    <NavItem href={ process.env.REACT_APP_PUBLIC_URL+ 'p/faq' } >{ process.env.REACT_APP_FAQ_TITLE }</NavItem>
                                    <NavItem href={ process.env.REACT_APP_PUBLIC_URL+ 'p/reglement' }  >{ process.env.REACT_APP_REGLEMENT_TITLE }</NavItem>
                                </SideNav>
                            </div>

                            <ul className="left hide-on-med-and-down">
                                { this.state.links_home_presentation }
                                { this.state.links_home_gagnants }
                                { this.state.links_home_participations }
                                { this.state.links_home_lots }
                                <li className="right">
                                    <ul>
                                        <NavItem href={process.env.REACT_APP_PUBLIC_URL+ 'p/faq' } >{ process.env.REACT_APP_FAQ_TITLE }&nbsp;&nbsp;+</NavItem>
                                        <NavItem href={process.env.REACT_APP_PUBLIC_URL+ 'p/reglement' }  >{ process.env.REACT_APP_REGLEMENT_TITLE }&nbsp;&nbsp;+</NavItem>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    
                </div>

        );
    }
}
export default withRouter(Navbar);
