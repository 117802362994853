import React, { Fragment }          from 'react';
import { 
    Card,
    Row, 
    Col,
}                                   from 'react-materialize';

import BtnParticipez                from '../components/commun/UI/BtnParticipez';
// import notice                       from '../img/notice.svg';
// import notice_mobile                from '../img/notice_mobile.svg';

const MainPresentation = () => {
    return (
        <Fragment>
            <Row className="d-flex m-0 app_screen">
                <Col className="app_presentation-tag" s={12} l={9}>
                    <div className="pl-5">
                        <h2>#PlusBeau<br/>ChezNous</h2>
                        <p><span className="bigger color_main">Grand Concours 2019</span><br/>
                        pour lutter contre les incivilités<br/>
                        liées à la propreté et l'hygiène</p>
                    </div>
                </Col>
                <Col s={12}l={3} className="app_presentation-participez valign-wrapper" >
                    <Card
                        className="m-0" 
                        header={ <h1 className="title_bordered"><span>{ process.env.REACT_APP_PRESENTATION_TITLE }</span></h1> }
                        actions={[ <BtnParticipez key="app_presentation-card-btn" /> ]}
                    >
                        <div dangerouslySetInnerHTML={{__html: process.env.REACT_APP_PRESENTATION_TXT}} />
                    </Card>
                </Col>
            </Row>  
      
        </Fragment>                    
    );
}

export default MainPresentation;