import React            from 'react';
import ReactPlayer      from 'react-player'
import { Button }       from 'react-materialize';
import empty            from "../img/empty.png";
import video_thumb      from '../img/video_thumb2.png';
import file_thumb       from '../img/file_thumb2.png';
import icon_doc         from '../img/file_thumb2.png';

export const getType = (filetype) => {
    if( filetype ) {
        var type = filetype.split("/")[0];
        return type;
    } else {
        return 'file';
    }
}
export const getTypeName = (videolink, filetype) => {
    if( filetype ) {
        var type = getType(filetype);
        
        if(type === "image") {
            return "Photo";
        } else if(type === "video") {
            return "Vidéo";
        } else {
            return "Doc";
        }
    } else if(videolink) {
        return "Vidéo";
    } else {
        return null;
    }
}
export const getMedia = (videolink, filename, filetype) => {
    if(videolink) {
        return video_thumb;
    } 
    else if( filename) {
        var type = getType(filetype);
        
        if(type === 'video' || filename==='Ma_1554282471719.pdf') { 
            // return video_thumb;
            var file_basename = filename.substr(0, filename.lastIndexOf('.'));
            return process.env.REACT_APP_FILE_PATH+'thumb/'+file_basename+'.jpg'; 
        } else if(type === 'image') {
                return process.env.REACT_APP_FILE_PATH+filename;
                
        } else {

            return file_thumb;
        }
    } else {
        return empty;
    }
}
export const showMediaMsg = () => {
    return(
        <p className="color_main left-align">Le contenu ne s'affiche pas ?<br/>Le chargement peut mettre entre 2 à 20 min. Rafraîchissez votre page ultérieurement ou changez de navigateur.</p>
    );
}
export const showMedia = (videolink, filename, filetype) => {
    if(videolink) {
        return(<ReactPlayer url={videolink} className="mt-5" /> );   
    }
    else if(filename && filetype) {
        var type = getType(filetype);
        var file_basename = filename.substr(0, filename.lastIndexOf('.'));
        var file_basepath = process.env.REACT_APP_FILE_PATH;

        if(type === 'image') {
            return(<img key="media_1" alt="Concours ES 2019" style={{  maxHeight: '500px' }} src={ file_basepath+filename } className="responsive-img" />);
        } 

        else if(type === 'video') {
            var video_name = file_basename+'.mp4';
            // var video_basepath = file_basepath+'converted/';
            return(<div><ReactPlayer key={'medias_reactPlayer_'+{filename}} className="media_video" controls url={ file_basepath+video_name }  /></div> );
        }
        else {
            return(<p className="center-align" key={'medias_default_'+{filename}}><a className="d-flex flex-direction-column " target="_blank" rel="noopener noreferrer" href={ file_basepath+filename }>
            <span className="pt-5 mt-5"><img src={ icon_doc } width="150px" alt="" /></span>
            <br/>
            <Button className="bg_main">Lire</Button>
            </a></p>);
        }
    } else {
        return null;
    }
}
