import React                    from 'react';
import ReactDOM                 from 'react-dom';
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import * as serviceWorker       from './serviceWorker';
import { 
    createStore,
    applyMiddleware }           from 'redux';
import rootReducer              from './store/reducers/index';
import { fetchAllPosts }        from './store/actions/postActions';
import { Provider }             from 'react-redux';
import thunk                    from 'redux-thunk';
import {composeWithDevTools }   from 'redux-devtools-extension';

import App                      from './App';
import './assets/scss/index.scss';
import "animate.css/animate.min.css";

const store = createStore(
    rootReducer, 
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);
store.dispatch(
    fetchAllPosts()
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
