import React                    from 'react';
import { Row, Col }             from 'react-materialize';
import Navbar                   from '../components/commun/Navbar';
import Footer                   from '../components/commun/Footer';

const Faq = () => {
    return (
        <div>
            <Navbar />
            <div id="app_faq" className="app_usual container-fluid">
                <Row className="pt-5 m-0" >  
                    <Col className="white p-100 mt-5 mb-5" offset="l2" s={ 12 } l={ 8 }>
                        <h1 dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_FAQ_TITLE }} />
                        <div dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_FAQ_DESC }} />
                    </Col>
                </Row>
            </div>
            <Footer />
        </div>
    );
    
}

export default Faq;
