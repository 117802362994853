import React, { Component }             from 'react';
import { compose }                      from 'redux';
import { connect }                      from 'react-redux';
import { Link }                         from 'react-router-dom';
import PostSummary                      from '../components/commun/PostSummary';
import { fetchPost }                    from '../store/actions/postActions';

class PostSingle extends Component {
    render() {
        const { post } = this.props;
        if(post) {
            return (
                <div id="post_details" className="container section h-100vh">
                    <Link className="link_home" to="/" >
                        <b>Revenir sur la page d'accueil du Concours</b>
                    </Link>
                    
                    <PostSummary display="single" post={post}  />
                </div>
            );
        } else {
            return(
                <div className="container center">
                    <p>Chargement en cours..</p>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const _id = ownProps.match.params.id;
    const posts = fetchPost(state.posts, _id);
    const post = posts ? posts : '';

    return {
        post: post,
    }
}
export default compose(
    connect(mapStateToProps, null)
)(PostSingle);
