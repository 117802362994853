import React, { Component, Fragment }       from 'react';
import { Card, CardTitle, Col, Button }     from 'react-materialize';
import PostModal                            from './PostModal';

import { 
    getMedia, 
    showMedia, getType 
}                                           from '../../utils/medias';



class PostSummary extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            postDate: null,
            showThumb: true,
            showVideo: false,
            showMoreBt: true,
            showFiles: false
        };
        if(props.display) {
            this.state = {
                postDate: null,
                showThumb: false,
                showVideo: true,
                showMoreBt: false,
                showFiles: true
            }
        }
    }
    componentDidMount() { 
        this.setState({
            postDate: this.props.post.createdAt
        });
    }
    render() {
            
            const { display_table, post } = this.props;
            var tableThumb = {
                maxWidth: '300px',
                overflow: 'hidden'
            };
            const PostLink = () => {
                if(!this.state.showMoreBt) { return (null); }
                return (<span className="mt-5 btn-link black-text">{ process.env.REACT_APP_PARTICIPATION_SEE }</span>);
            }
            const Thumb = () => {  
                if(!this.state.showThumb || (post.videolink === '' && post.filename === '')) {
                    return (null);
                }
                return (
                    <Fragment>
                        { display_table !== true && <div className="media_type">{ post.rank }</div> }
                        { <CardTitle className={"card-image type_"+ getType(post.filetype)} image={ getMedia(post.videolink, post.filename, post.filetype) } /> }
                    </Fragment>
                );
            }
            const Files = () => {
                return (
                    <Fragment>
                        {
                            showMedia(post.videolink, post.filename, post.filetype)
                        }
                    </Fragment>
                );
            }
        

            if(display_table === true) {
                return (
                        <tr style={ tableThumb }> 
                            <td  ><Thumb /></td>
                            <td>{ post.rank}</td>
                            <td>{ post.name }</td>
                            <td>{ post.pseudo }</td>
                            <td><div className="hide-on-small-only">{`${ post.descriptif }` }</div></td>
                            <td className="center-align "><PostModal post={ post } trigger={<Button className="valign-wrapper">Voir</Button>} /></td>
                        </tr>
                );
            } else { 
                return (
                    <Col s={12} l={4} xl={3} > 
                        <PostModal post={ post } trigger={
                        <Card key={'card_'+post._id} 
                            className='post_list-summary h-100 z-depth-2'
                            title= {''}
                            header= { <Thumb /> }
                            actions={''} >
                                { this.state.showFiles && 
                                    <Files />
                                }
                                <h2>{ post.name }&nbsp;</h2>
                                {
                                    post.pseudo && <p className="pseudo small pb-5">Auteur : {`${ post.pseudo }`}&nbsp;</p>
                                }
                                {
                                    post.descriptif && <p>{`${ post.descriptif }`.slice(0, 50)+' ...'}</p>
                                }
                                <PostLink className="btn-top" key={'postlink_'+post._id} />
                        </Card>
                        } />
                    </Col>
                );
            }
    }
}
export default PostSummary;