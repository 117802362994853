import React, { Component, Fragment }          from 'react';
import { Row, Col }                 from 'react-materialize';
import Navbar                       from '../components/commun/Navbar';
import Footer                       from '../components/commun/Footer';
import PostListInfinite             from '../components/commun/PostListInfinite';
const axios = require('axios');

class Posts extends Component {
    state= {
        nb_total: null
    }
    componentDidMount() {
        this.getNbTotal();
    }
    getNbTotal() {
        axios.get(process.env.REACT_APP_API_URL+'/posts')
        .then((res) => {
            this.setState({
                nb_total: res.data.filter(post=>post.isoffline !== true).length
            });
        })
    }

    render() {
        // const { posts } = this.props;
        return (
            <Fragment>
                <Navbar />
                <div id="posts" className="container section h-100vh">
                    <h2 className="s12 ">{ this.state.nb_total }&nbsp;<span>projet{ this.state.nb_total > 1 ? 's' :'' }</span></h2>
                    <Col s={12} id="posts__list" className="mb-5 pb-5">
                        <Row className="d-flex">
                            <PostListInfinite nb_total={ this.state.nb_total } />
                        </Row>
                    </Col>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
export default Posts;
/*
const mapStateToProps = (state) => { 
    var sort_posts = state.posts.sort((a,b) => {
            return new Date(a.createdAt).getTime()-new Date(b.createdAt).getTime()
    }).reverse();
    // var _posts = sort_posts.slice(0, 4); 
    var _posts = sort_posts; 
    return {
        posts: _posts
    }
}
export default compose(  
    connect(mapStateToProps),
)(Posts);
*/