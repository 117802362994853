import React, { Component, Fragment }       from 'react';
import { Slide } from 'react-slideshow-image';
import Image1 from '../../img/gagnants_slider/1.jpg';
import Image2 from '../../img/gagnants_slider/2.jpg';
import Image3 from '../../img/gagnants_slider/3.jpg';
import Image4 from '../../img/gagnants_slider/4.jpg';
import Image5 from '../../img/gagnants_slider/5.jpg';
import Image6 from '../../img/gagnants_slider/6.jpg';
import Image7 from '../../img/gagnants_slider/7.jpg';
import Image8 from '../../img/gagnants_slider/8.jpg';
import PostModal from './PostModal';
import Gallery from './Gallery';

import data from '../../datas/Gagnants';

class SliderGagnants extends Component {
    render() {
        const properties = {
            duration: 5000,
            transitionDuration: 500,
            infinite: true,
            indicators: true,
            arrows: true,
            onChange: (oldIndex, newIndex) => {
                console.log(`slide transition from ${oldIndex} to ${newIndex}`);
            }
        }
        const Slideshow = () => {
            
            return (
                <div className="slide-container">
                    <Slide {...properties}>
                        <PostModal isGagnant="true" key={ 0 } post={ data[0] } trigger={
                            <div className="each-slide">
                                <div style={{'backgroundImage': `url(${Image1})`}}>
                                    <span>1er Prix : Nos chanteuses, Julia & Lou, heureuses de leur prestation musicale</span>
                                </div>
                            </div>
                        } />
                        <PostModal isGagnant="true" key={ 1 } post={ data[1] } trigger={
                            <div className="each-slide">
                                <div style={{'backgroundImage': `url(${Image2})`}}>
                                <span>Grand Prix du Jury : Laurent, le papa, l’Artiste Elzéar et Nathalie, leur Gardienne d’immeuble</span>
                                </div>
                            </div>
                        } />
                        <PostModal isGagnant="true" key={ 2 } post={ data[2] } trigger={
                            <div className="each-slide">
                                <div style={{'backgroundImage': `url(${Image3})`}}>
                                <span>2ème prix : Lydia, Gardienne d’immeuble de notre artiste, Alex, accompagnée de nos maîtres de cérémonie</span>
                                </div>
                            </div>
                        } />
                        <PostModal isGagnant="true" key={ 3 } post={ data[3] } trigger={
                            <div className="each-slide">
                                <div style={{'backgroundImage': `url(${Image4})`}}>
                                <span>3ème prix : Kevin, Responsable de secteur de La Celle Saint-Cloud, lisant le poème de Dalila</span>
                                </div>
                            </div>
                        } />
                        <PostModal isGagnant="true" key={ 4 } post={ data[4] } trigger={
                            <div className="each-slide">
                                <div style={{'backgroundImage': `url(${Image5})`}}>
                                <span>4ème prix : Renaud et sa fille et artiste Jade, accompagnés par Zoia, Gardienne de leur immeuble</span>
                                </div>
                            </div>
                        } />
                        <PostModal isGagnant="true" key={ 5 } post={ data[5] } trigger={
                            <div className="each-slide">
                                <div style={{'backgroundImage': `url(${Image6})`}}>
                                <span>5ème prix : Moment de discours de nos maîtres de cérémonie </span>
                                </div>
                            </div>
                        } />
                        <PostModal isGagnant="true" key={ 6 } post={ data[6] } trigger={
                            <div className="each-slide">
                                <div style={{'backgroundImage': `url(${Image7})`}}>
                                <span>6ème prix : Danièle Premel, Présidente d'Elogie-Siemp, donnant le prix à notre artiste, Antoine</span>
                                </div>
                            </div>
                        } />
                        <PostModal isGagnant="true" key={ 7 } post={ data[7] } trigger={
                            <div className="each-slide">
                                <div style={{'backgroundImage': `url(${Image8})`}}>
                                <span>6ème prix : Discours de Ian Brossat, Adjoint à la Maire de Paris chargé de toutes les questions relatives au logement, à l’habitat durable et à l’hébergement d’urgence</span>
                                </div>
                            </div>
                        } />
                    </Slide>
                    <p className="right-align"><i>Photographies : ©Mehrak</i></p>
                </div>
            )
        }
        
        return (
            <Fragment>
                <Slideshow />
                <Gallery />    
            </Fragment>
        );        
    }
}
export default SliderGagnants;