import React, { Component, Fragment }       from 'react';
import InfiniteLoader                       from 'react-infinite-loader';
import PostSummary                          from './PostSummary';
const axios = require('axios');

// REACT_API_URL+'/posts/select?skip=0&limit=5

class PostListInfinite extends Component {
    state= {
        items: [],
        start: 0,
        nb_items: 8,
        fullyLoaded: false
    }

    loadItems() {  
            var items_ = this.state.items;
            axios.get(process.env.REACT_APP_API_URL+'/posts/select?skip='+this.state.start+'&limit='+this.state.nb_items)
            .then((res) => {  
                setTimeout(() => {
                    this.setState({
                        items: items_.concat(res.data),
                        start: this.state.start+this.state.nb_items
                    });
                }, 1000) 
            });
            if(this.state.start > this.props.nb_total) {
                setTimeout(() => {
                    this.setState({
                        fullyLoaded: true
                    });
                    document.body.classList.add('fullyloaded');
                }, 3000)
            }
    }
    handleVisit() {
        if(!this.state.fullyLoaded) {
            this.loadItems();
        }
        
    }
    renderCards() {
        const { items } = this.state;        
    
        if(items && items.length > 0) {
            const cards = items.map((post, i) => {
                return (<PostSummary post={ post } key={'postsummary'+post._id} />);                
            })
            return cards;
        } else {
            return false;
        }
    }
    
    render() {
        
        return (
            <Fragment>
                { this.renderCards() }
                <InfiniteLoader pageStart={0} hasMore='true' onVisited={ () => this.handleVisit() } />
            </Fragment>
        );
        
    }
}
export default PostListInfinite;
