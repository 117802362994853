import React from 'react';
import ImgLaurier                           from '../../img/icons/laurier.png';
import ImgCoupe                             from '../../img/icons/coupe.png';
import imgHeart                             from '../../img/icons/heart.png';

const IconGagnants = (props) => {
    const { rank } = props;
    
    if(rank === '0') {
        return <img src={ imgHeart  } alt="ES" />
    } else if(rank === '1') {
        return <img src={ ImgCoupe } alt="ES" />
    } else {
        return <img src={ ImgLaurier } alt="ES" />
    }
}
export default IconGagnants;