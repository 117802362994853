import React, { Component } from 'react';
import { showMedia, getType } from '../../utils/medias';
import { Modal, Row, Col } from 'react-materialize';
import IconGagnants from './IconGagnants';
class PostModal extends Component {
    state = {  }
    
    render() {
        const { post, isGagnant } = this.props;
        const ModalContent = () => {
            return (
                <div  className="post_list-summary">
                    <Row className="d-flex">
                        <Col s= { 12 } xl={ 5 }>
                            <div className="d-flex h-100 justify-content-between">
                                <div className="w-100 mr-5">
                                    <p className="pseudo mt-5">Auteur : { post.pseudo }</p>
                                    <p className="mt-5">{ post.descriptif }</p>
                                </div>
                            </div>
                        </Col>
                        <Col className={"media " + getType(post.filetype) } s= { 12 }  xl={ 7 }>
                            <div className="right-align">
                                {
                                showMedia(post.videolink, post.filename, post.filetype)
                                }
                                
                            </div>
                        </Col>
                    </Row>  
                </div>
            );
        }
        const ModalContentGagnant = () => {
            const ext = (post.rank === "1" ? "<sup>er</sup>": "<sup>ème</sup>");
            const rank = (post.rank === '0' ? "Grand prix du jury": post.rank + ext + " prix");
        
            return (
                <div className="post_list-summary">
                    <center>
                        <IconGagnants rank={post.rank } />
                        <h3 dangerouslySetInnerHTML={{__html: rank}} />
                        <p className="pseudo mt-5 mb-0">{ post.pseudo }</p>
                        <p className="m-0">{ post.lieu }</p>
                    </center>
                    <Row className="d-flex">
                        <Col className={ getType(post.filetype) } s= { 12 }  xl={ 3 }>
                            <div className="center-align">
                                <img className="responsive-img" alt="Concours 2019" src={ require('../../img/gagnants_modalpic/'+post._id+'.jpg') } />
                            </div>
                        </Col>
                        <Col s= { 12 } xl={ 4 }>
                            <div className="d-flex h-100 justify-content-between">
                                <div className="w-100 mr-5">
                                    <p className="mt-5" dangerouslySetInnerHTML={{__html: post.itv1 }} />
                                    <p className="mt-5" dangerouslySetInnerHTML={{__html: post.itv2 }} />
                                </div>
                            </div>
                        </Col>
                        <Col className={ getType(post.filetype) } s= { 12 }  xl={ 4 }>
                            <div className="center-align">
                                {
                                showMedia(post.videolink, post.filename, post.filetype)
                                }
                            </div>
                        </Col>
                    </Row> 
                    
                </div>
            );
        }
        return (
            <Modal
                id= { 'modal_'+post._id }
                header={ post.name }
                trigger={ this.props.trigger }>
                {
                    (isGagnant ? <ModalContentGagnant /> : <ModalContent />)
                }
            </Modal>
        );
    }
}
export default PostModal;