import React, { Component, Fragment }       from 'react';
import { Card, CardTitle, Col }             from 'react-materialize';
import {  
    getMedia 
}                                           from '../../utils/medias';
import PostModal                            from './PostModal';   
import IconGagnants                         from './IconGagnants';    
import data                                 from '../../datas/Gagnants';

class PostGagnants extends Component {
    render() {
        const Thumb = (props) => {  
            const {post } = props;
            return (
                <Fragment>
                    { <CardTitle className={"card-image type_image"} image={ getMedia(post.videolink, post.filename, post.filetype) } /> }
                </Fragment>
            );
        }
        
        
        const postsList = (
            data && data.map((post, i) => {
                
                const col = (post.rank < 2 ? 6 : 4);
                
                return (
                    <Col key={ i } s={12} l={ col } className="mb-5" > 
                        <PostModal isGagnant="true" key={ post.id } post={ post } trigger={
                            <Card key={'card_'+post._id} 
                                className='post_list-summary h-100 z-depth-2'
                                title= {''}
                                header= { <center><img className="mt-5" src={ require(`../../img/icons/prix_${post.rank}.png`) } alt="ES" /></center>}
                                actions={ '' } >
                                    <IconGagnants rank={post.rank } />
                                
                                    <h2>{ post.name }&nbsp;</h2><br/>
                                    <p><center>{ post.pseudo }<br/>{ post.lieu }</center></p>
                                <Thumb post={post} />
                            </Card>
                        } />
                    </Col>
                );
            })
        );

        
        return (
            <Fragment>
                { !postsList || postsList.length === 0 ? '' : postsList } 
            </Fragment>
        );        
    }
}
export default PostGagnants;