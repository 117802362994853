import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const images = [
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/1.jpg", "desc":"Remise des prix du concours Plus Beau Chez Nous."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/2.jpg", "desc":"Discours de Valérie de Brem, Directrice d'Elogie-Siemp en présence de Ian Brossat, Adjoint à la Maire de Paris chargé de toutes les questions relatives au logement, à l’habitat durable et à l’hébergement d’urgence."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/3.jpg", "desc":"Danièle Premel, Présidente d’Élogie-Siemp, donnant le prix à Antoine."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/4.jpg", "desc":"Isabelle, Gardienne d’immeuble et notre artiste et locataire, Antoine, accompagnés des maîtres de cérémonie."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/5.jpg", "desc":"Renaud et sa fille Jade, accompagnés par Zoia, Gardienne de leur immeuble et nos maîtres de cérémonie."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/6.jpg", "desc":"Jade, très à l’aise au micro, nous expliquant son oeuvre."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/7.jpg", "desc":"Kevin, Responsable de secteur de La Celle Saint-Cloud tenant le poème de Dalila et entouré par nos maîtres de cérémonie."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/8.jpg", "desc":"Lydia, Gardienne d’immeuble de notre artiste, Alex, félicitée par Danièle Premel, Présidente d'Elogie-Siemp."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/9.jpg", "desc":"L’affiche d’Alex mise en avant par sa gardienne, Lydia et nos maîtres de cérémonie."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/10.jpg", "desc":"Elzéar félicité par Ian Brossat, maître de cérémonie et Adjoint à la Maire de Paris."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/11.jpg", "desc":"Notre artiste Elzéar et Nathalie, sa Gardienne d’immeuble accompagnés des maîtres de cérémonie."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/12.jpg", "desc":"Sandra, Gardienne d’immeuble, accompagnée de ses locataires Julia & Lou."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/13.jpg", "desc":"Nos grandes gagnantes et nos maîtres de cérémonie."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/14.jpg", "desc":"Nos chanteuses Julia & Lou en pleine performance musicale."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/15.jpg", "desc":"Plus qu'une chanson, un combat pour le respect de son environnement interprétée par Julia & Lou."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/16.jpg", "desc":"Elisa & Léa, filles de nos collaborateurs, félicitées pour leurs oeuvres lors de la remise des prix."},
    {"img": process.env.REACT_APP_PUBLIC_URL+"img/gagnants_gallery/17.jpg", "desc":"Tous nos gagnants réunis pour la remise des prix de notre concours Plus Beau Chez Nous."}
];

export default class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false
        };
    }

    render() {
        const { photoIndex, isOpen } = this.state;

        return (
            <div>
                <p className="center-align cursor-pointer d-block mt-5 mb-5 pb-5"><button className="btn waves-effect btn-large bg_main" onClick={() => this.setState({ isOpen: true })} >Voir la galerie</button></p>
                
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]["img"]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        imageCaption={images[photoIndex]["desc"]}
                        prevSrc={
                            images[
                                (photoIndex + images.length - 1) % images.length
                            ]
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + images.length - 1) %
                                    images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
