import React                         from 'react';
import { Link }                      from 'react-scroll';

import arrowb                        from '../../../img/icons/arrow_w_b.svg';

const BtnParticipez = (props) => { 
    return(
        <Link key="app_presentation-link" className={"btn waves-effect btn-large bg_main left-align d-flex valign-wrapper w-100 "+props.className} to="app_gagnants" spy={true} smooth={true} offset={-64} duration= {1500} ><span>Voir les gagnants</span><i className="mr-5"><img height="20px" src={ arrowb } alt="" /></i></Link>
    );
}
export default BtnParticipez;