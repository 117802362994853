// postActions.js
import {
    ADD_POST,
    DELETE_POST,
    FETCH_POST,
    CREATE_VOTE
} from './types';
import axios from 'axios';
var qs = require('qs');

const apiUrl = process.env.REACT_APP_API_URL+'/posts';


export const createPost = ({id_number, firstname, lastname, name, pseudo, descriptif, email, filename, filetype, votes, videolink, isgardien}) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/add`, qs.stringify({
            id_number,
            name,
            firstname,
            lastname,
            pseudo,
            descriptif,
            email,
            votes,
            filename,
            filetype,
            videolink,
            isgardien  
        }), 
            {
                headers: { 
                    // 'Content-Type': 'application/json;charset=UTF-8',
                    // 'Access-Control-Allow-Origin' : '*',
                    'Access-Control-Allow-Origin' : process.env.REACT_APP_API_URL,
                    'Content-Type':'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Accept':'application/json; charset=utf-8'
                }
            }
        )
        .then(response => {
            dispatch(createPostSuccess(response.data))
        })
        .catch(error => {
            throw (error);
        });
    };
};

export const createPostSuccess = (data) => {
    return {
        type: ADD_POST,
        payload: {
            _id: data._id,
            id_number: data.id_number,
            firstname: data.firstname,
            lastname: data.lastname,
            pseudo: data.pseudo,
            name: data.name,
            descriptif: data.descriptif,
            email: data.email,
            votes: data.votes,
            filesname: data.filename,
            filetype: data.filetype,
            videolink: data.videolink,
            isgardien: data.isgardien
        }
    }
};
export const deletePost = id => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/delete/${id}`)
            .then(response => {
                dispatch(deletePostSuccess(response.data))
            })
            .catch(error => {
                throw (error);
            });
    };
};
export const deletePostSuccess = id => {
    return {
        type: DELETE_POST,
        payload: {
            id
        }
    }
}
export const fetchPost = (posts, id) => {
    let post = posts.find(post => post._id === id)
    return post
}

export const fetchAllPosts = () => {
    return (dispatch) => { 
        const posts = [
        {"_id":"5cc172405a580e700bf5f667","rank":"1er prix","isoffline":false,"isgardien":false,"pseudo":"Julia et Lou","name":"Je manifeste","descriptif":"Ma fille et moi avons eu l'idée d'écrire une chanson pour dénoncer l'incivilité. Ensuite, nous avons fait un petit clip. J'espère qu'il vous plaira, en tout cas cela nous aura permis de bien nous amuser et c'est le principal. Merci.","votes":19,"filename":"JE_1556120912425.mp4","filetype":"video/mp4","videolink":"","createdAt":"2019-04-25T08:39:28.254Z"},  
        {"_id":"5cbb85330e686a7afab99418","rank":"Grand prix du public","isoffline":false,"isgardien":true,"pseudo":"ELZE","name":"POLLUTION JE DIS NON MAIS A LA VIE JE DIS OUI","descriptif":"J'ai voulu représenter les incivilités dans mon immeuble et ses alentours. Certains habitants ne se soucient pas de leur environnement : crachats, jets d’ordures, déjections, urines, mégots. Je dis stop. Ouvrez les yeux et agissez !","votes":18,"filename":"Pollution_1555793198017.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-04-20T20:46:43.511Z"},
        {"_id":"5cdbe26b32c9aa51c0eabda1","rank":"2ème prix","isoffline":false,"isgardien":true,"pseudo":"Alex","name":"Et si on essayait de faire plus propre ?","descriptif":"Avec cette affiche caricaturale, je souhaite faire réagir positivement les citoyens et faire que notre ville soit plus propre.","votes":17,"filename":"affiche_RATs_ville_1557913731757.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-05-15T09:56:59.036Z"},  
        {"_id":"5ca4782231411f7dd3df2d9a","rank":"3ème prix","isoffline":false,"isgardien":false,"pseudo":"Lilada","name":"Ferme les yeux et imagine...","descriptif":"Au travers d'une douce poésie, rappelons-nous à quel point c'est important de respecter notre environnement afin de vivre heureux dans notre ville…","votes":16,"filename":"Ma_1554282471719.pdf","filetype":"application/octet-stream","videolink":"","createdAt":"2019-04-03T09:08:50.832Z"}, 
        {"_id":"5cd85f347437f41ea89c4933","rank":"4ème prix","isoffline":false,"isgardien":false,"pseudo":"Jade","name":"Bien vivre ensemble","descriptif":"Quelques conseils pour bien vivre ensemble....","votes":15,"filename":"20190512_195111_1557683976431.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-05-12T18:00:20.429Z"},
        {"_id":"5ca248cf0322384da8d229ac","rank":"5ème prix","isoffline":false,"isgardien":false,"pseudo":"Sylvie","name":"Pour nos enfants, vivons bien ensemble","descriptif":"Vivons bien ensemble et montrons le bon exemple à nos enfants. Pour cela, respectons-nous, nos lieux de vie et cessons de saccager la nature.","votes":14,"filename":"s_moncourt.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-04-01T17:22:23.529Z"},
        {"_id":"5ca237ba0322384da8d229ab","rank":"6ème prix","isoffline":false,"isgardien":false,"pseudo":"Antoine","name":"Scène d'incivilités","descriptif":"Représentée sous la forme d'une scène de crime, ma scène d'incivilité expose tous les indices recueillis dans la cour de mon immeuble.","votes":13,"filename":"Sce_1554134768145_2.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-04-01T16:09:30.040Z"},
        {"_id":"5c9f6a5e0322384da8d229a7","rank":"6ème prix","isoffline":false,"isgardien":false,"pseudo":"Eva","name":"Respectons tous ensemble notre habitat commun","descriptif":"Un petit effort de propreté de la part de chacun et un grand plaisir à vivre pour tous !","votes":12,"filename":"Eva_1553951315738_2.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-03-30T13:08:46.643Z"},  
        {"_id":"5ca7463ee5be71177d8d3baa","rank":"*","isoffline":false,"isgardien":false,"pseudo":"Magicmik","name":"Une ville idéale","descriptif":"Ne serait-il pas bon de retrouver chaque jour une ville où respect hygiène et propreté sont toujours au rendez-vous? Moi j'en rêve! Faisons en sorte que ce rêve devienne une réalité...","votes":11,"filename":"Image_1554466359807.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-04-05T12:12:46.245Z"},   
        {"_id":"5cd044a7d306c41b34c1a1df","rank":"*","isoffline":false,"isgardien":false,"pseudo":"laploubelle","name":"🎶Ce soir je sortirai la poubelle pour aller danser ! 🎶","descriptif":"Dans mon immeuble, nous nettoyons nos parties communes entre voisins et nous nous chargeons des poubelles. Il m’arrive de sortir la poubelle en robe de soirée ce qui provoque l’étonnement des gens dans la rue. Poubelle la vie !","votes":10,"filename":"IMG_20190506_162403_1557152718818.JPG","filetype":"image/jpeg","videolink":"","createdAt":"2019-05-06T14:28:55.201Z"},  
        {"_id":"5cd884da82afab3bdc379934","rank":"*","isoffline":false,"isgardien":false,"pseudo":"GINCA","name":"Besoin de respect","descriptif":"Ras le bol le bol PMPM","votes":9,"filename":"Besoinderespect_1557692788019.pdf","filetype":"application/pdf","videolink":"","createdAt":"2019-05-12T20:40:58.859Z"},
        {"_id":"5cdb8a387531ef6dba1cbd33","rank":"*","isoffline":false,"isgardien":false,"pseudo":"METROPIXO","name":"INTERDIT DE STATIONNER","descriptif":"Film portant sur les comportements entres voisins. Un immeuble, des voisins qui ne se parlent pas, des incivilités débordant le quartier. Et un personnage, hors du commun, inconscient du risque qu'il fait encourir !","votes":8,"filename":"INTERDIT_DE_STATIONNER_1557926439840.mp4","filetype":"video/mp4","videolink":"","createdAt":"2019-05-15T03:40:40.610Z"},
        {"_id":"5cdc348d32c9aa51c0eabda3","rank":"*","isoffline":false,"isgardien":false,"pseudo":"PH.CANCES","name":"Toutes les  couleurs du respect","descriptif":"kkkg","votes":7,"filename":"Affiche_1557934787028.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-05-15T15:47:25.150Z"},
        {"_id":"5cd319b53572350cca15af23","rank":"*","isoffline":false,"isgardien":false,"pseudo":"Maï-Anna","name":"Irrespect","descriptif":"J'ai voulu montrer ce que subit le voisin d'une personne irrespectueuse (bruits, odeur...) - Maï-Anna, 11 ans","votes":6,"filename":"IMG_20190508_200034_1557338533909.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-05-08T18:02:29.665Z"},
        {"_id":"5ca0ba160322384da8d229aa","rank":"*", "isoffline":false,"isgardien":false,"pseudo":"Marguerite","name":"ENSEMBLE","descriptif":"Ma vision de vivre en communauté.","votes":5,"filename":"poeme_1554037266016.pdf","filetype":"application/vnd.openxmlformats-officedocument.wordprocessingml.document","videolink":"","createdAt":"2019-03-31T13:01:10.228Z"}, 
        {"_id":"5cd80828e192e711703a2416","rank":"*","isoffline":false,"isgardien":false,"pseudo":"MALIYA","name":"Le tri des Poubelles","descriptif":"Ajz tri mon poubelle dans  ","votes":4,"filename":"IMG_20190512_0001_1557661629330.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-05-12T11:48:56.055Z"},
        {"_id":"5cab8b04e5be71177d8d3bab","rank":"*","isoffline":false,"isgardien":false,"pseudo":"Juju","name":"l'arbre et les dames qui ramassaient le caca de leur chien","descriptif":"Deux dames ramassent le caca de leur chien, pendant ce temps là un oisillon essaye de rentrer dans son nid, une maman écureuil mange des noisettes et dans le petit trou les bébés écureuils mangent les plus petites noisettes de l'arbre.","votes":3,"filename":"ca8e1cb1-3074-410e-8269-5dbb9bcb04d5_1554746105009.JPG","filetype":"image/jpeg","videolink":"","createdAt":"2019-04-08T17:55:16.677Z"},
        {"_id":"5cdc785ac0861a10c02e1483","rank":"*","isoffline":false,"isgardien":true,"pseudo":"Caetano","name":"Civisme, penser à tous","descriptif":"Caetano, mon fils de 5 ans, a dessiné deux situations de non-civisme (caca de chien non ramassé, un homme qui jette un papier au sol). Le dessin de Caetano révèle sa préoccupation et fait appel au civisme de chacun, pour le bien commun.","votes":2,"filename":"concours_1557952461529.pdf","filetype":"application/pdf","videolink":"","createdAt":"2019-05-15T20:36:42.651Z"},
        {"_id":"5cdb22af7531ef6dba1cbd31","rank":"*","isoffline":false,"isgardien":false,"pseudo":"Cendrillon","name":"Dessin sur les incivilites","descriptif":"Les incivilités ne sont pas bien et respecter  tous les personnes de la immeubles et ne rien jeter par les fenetres","votes":1,"filename":"15578649194241577097098_1557864966806.jpg","filetype":"image/jpeg","videolink":"","createdAt":"2019-05-14T20:18:55.947Z"}
        ];
        dispatch(fetchPostSuccess(posts));
    };
};
export const fetchPostSuccess = (posts) => {
    return {
        type: FETCH_POST,
        posts
    }
};
export const createVote = (post) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/createvote/${post._id}`, qs.stringify({
            id: post._id,
            votes: post.votes+1
        }), 
        {
            headers: { 
                'Access-Control-Allow-Origin' : '*',
                'Content-Type':'application/x-www-form-urlencoded',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Accept':'application/json; charset=utf-8'
            }
        })
        .then(response => {
            dispatch(createVoteSuccess(response.data))
        })
        .catch(error => {
            throw (error);
        });
    };
};
export const createVoteSuccess = (data) => {

    return {
        type: CREATE_VOTE,
        payload: {
            ...data, 
            votes: data.votes+1
        }
    }
}