import {
    ADD_POST,
    DELETE_POST,
    FETCH_POST,
    CREATE_VOTE
} from '../actions/types';

export default function postReducer(state = [], action) {

    switch (action.type) {
        case ADD_POST:
            return [...state, action.payload];
        case DELETE_POST:
            return state.filter(post => post._id !== action.payload._id);
        case FETCH_POST:
            return action.posts.filter(post => post.isoffline !== true);
        case CREATE_VOTE:
            return state.map((post) => {
                if (post._id === action.payload._id) {
                    return action.payload;
                } else return post;
            })

        default:
            return state;
    }
}