import React, { Fragment }          from 'react';
import { 
    Tabs, 
    Tab
}                                   from 'react-materialize';
import BtnParticipez                from './commun/UI/BtnParticipez';
import lots1 from '../img/lots1.jpg';
import lots2 from '../img/lots2.jpg';
import lots3 from '../img/lots3.jpg';
import lots4 from '../img/lots4.jpg';
const Lots = () => {
    var tab_image1 = {
        backgroundImage: 'url(' + lots1 + ')',
    };
    var tab_image2 = {
        backgroundImage: 'url(' + lots2 + ')',
    };
    var tab_image3 = {
        backgroundImage: 'url(' + lots3 + ')',
    };
    var tab_image4 = {
        backgroundImage: 'url(' + lots4 + ')',
    };

    return (
            <Fragment>
                <Tabs title="tab">
                <Tab title={ process.env.REACT_APP_LOTS_TITRE1 } active >
                        <h3>{ process.env.REACT_APP_LOTS_TITRE1 }</h3>
                        <div className="tab-container">
                            <div className="tab_content">
                                <p>4 places pour le parc Disneyland Paris<br/></p>
                                <p className="tab_chapo">Voyagez dans l’univers féérique de Disney en gagnant une journée pour 4 personnes dans l’un des deux parcs de Disneyland Paris : Disneyland ou Walt Disney Studios ! De nombreuses attractions vous attendent !</p><br/>
                                <p><b>Conditions :</b><br/>
                                Billets valables pour des adultes ou enfants, pendant 1 an à partir de mai 2019.<br/>
                                Valeur unitaire du billet : 79 € - Valeur totale du lot : 316 €.<br/>
                                Adresse : Boulevard de Parc, 77700 Coupvray<br/>
                                Plus d’infos et conditions sur <a rel="noopener noreferrer" href="https://www.disneylandparis.com/fr-fr/" target="_blank">www.disneylandparis.com</a>.</p>
                                <br/>
                                <p><b>Pour le gardien de votre résidence :</b><br/>1 carte cadeau multi-enseignes d'une valeur de 80 €.</p>
                
                            </div>
                            <div className="tab_image" style={ tab_image1 }><span>© Pixabay</span></div>
                        </div>
                    </Tab>
                    <Tab title={ process.env.REACT_APP_LOTS_TITRE2 }  >
                        <h3>{ process.env.REACT_APP_LOTS_TITRE2 }</h3>
                        <div className="tab-container" >
                            <div className="tab_content">
                                <p>4 places pour le parc de réalité virtuelle Illucity<br/></p><p className="tab_chapo">Cassez la routine en gagnant 4 places pour le parc de réalité virtuelle Illucity&nbsp;! Ce terrain de jeux de 1000 m² vous emmènera dans une autre dimension pour vivre de nombreuses aventures (jeux vidéo, escape game, cinéma, arcade...).</p><br/>
                                <p><b>Conditions : </b><br/>
                                Cartes cadeaux valables pour des adultes ou enfants, pendant 6 mois à partir de mai 2019 sur toutes les expériences du parc.<br/>
                                Valeur unitaire du billet : 60 € - Valeur totale du lot : 240 €.<br/>
                                Adresse : 32 Avenue Corentin Cariou, 75019 Paris.<br/>
                                Plus d’infos et conditions sur <a rel="noopener noreferrer" href="https://illucity.fr/fr/" target="_blank">illucity.fr</a>.
                                </p><br/>
                                <p><b>Pour le gardien de votre résidence :</b><br/>1 carte cadeau multi-enseignes d'une valeur de 80 €.</p>
       
                            </div>
                            <div className="tab_image" style={ tab_image2 }><span>© Illucity</span></div>
                        </div>
                    </Tab>
                    <Tab title={ process.env.REACT_APP_LOTS_TITRE3 }  >
                        <h3>{ process.env.REACT_APP_LOTS_TITRE3 }</h3>
                        <div className="tab-container" >
                            <div className="tab_content">
                                <p>4 places pour le parc Laser World Paris Montparnasse</p><p className="tab_chapo">Affrontez vos adversaires dans un labyrinthe aux décors stupéfiants en gagnant 4 places pour le  parc  de  jeux  laser Laser World  Paris  Montparnasse ! Action en immersion totale garantie sur plus de 500 m² de terrain !</p><br/>
                                <p><b>Conditions :</b><br/>
                                Billets valables pour des adultes ou enfants, pendant 1 an à partir de mai 2019, pour 3 parties de 20 min avec pauses.<br/>
                                Parc accessible à partir de 1m20 et dès 5 ans avec un adulte.<br/>
                                Valeur unitaire du billet : 28 € - Valeur totale du lot : 112 €.<br/>
                                Adresse : 168 Boulevard du Montparnasse, 75014 Paris.<br/>
                                Plus d’infos et conditions sur <a href="https://laser-world-paris.fr/" target="_blank" rel="noopener noreferrer">laser-world-paris.fr</a>.</p><br/>
                                <p><b>Pour le gardien de votre résidence :</b><br/>1 carte cadeau multi-enseignes d'une valeur de 80 €.</p>
                            </div>
                            <div className="tab_image" style={ tab_image3 }><span>© Laser World Paris Montparnasse</span></div>
                        </div>
                    </Tab>
                    <Tab title={ process.env.REACT_APP_LOTS_TITRE4 }  >
                        <h3>{ process.env.REACT_APP_LOTS_TITRE4 }</h3>
                        <div className="tab-container" >
                            <div className="tab_content">
                                <p>2 places pour une séance de cinéma</p><p className="tab_chapo">Profitez d’un bon film, seul(e) ou à deux, en gagnant 2 places pour une séance de cinéma dans l’un des 1350 cinémas partenaires du Groupe CinéChèque !</p><br/>
                                <p><b>Conditions :</b><br/>
                                Pochette valable pour des adultes ou enfants, pendant 6 mois à partir de mai 2019. Sans valeur faciale, les places sont acceptées en direct, sans restriction, ni complément de prix, à toutes les séances de l’ensemble du réseau de cinéma.<br/>
                                Valeur unitaire du billet : 8,50 € - Valeur totale de la pochette : 17 €.<br/>
                                Plus d’infos et conditions sur <a href="https://www.boutique-cinecheque.fr/" target="_blank" rel="noopener noreferrer">www.boutique-cinecheque.fr</a></p><br/>
                                <p><b>Pour le gardien de votre résidence :</b><br/>2 places de cinéma d'une valeur de 17 €.</p>
                            </div>
                            <div className="tab_image" style={ tab_image4 }><span>© Pixabay</span></div>
                        </div>
                    </Tab>
                </Tabs>
                <BtnParticipez className="hide-on-large-only show-on-medium-and-down" />
            </Fragment>
    );
}
export default Lots;