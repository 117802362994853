import React, { Component, Fragment }       from 'react';
import PostSummary                          from './PostSummary';
import EmptyMsg from '../../utils/EmptyMsg';
import { Table }             from 'react-materialize';

class PostList extends Component {
    render() {
        const { display_table, posts } = this.props;
        var visuStyle = {
            width: '300px'
        };
        var txtStyle = {
            width: '300px'
        };
        var smallStyle = {
            width: '100px'
        };
        var tableHead = {
            maxWidth: '200px',
        };    
        var tableTh = {
            textAlign: 'left',
        };

        const postsList = (
            posts && posts.map(post => {
                return (
                    <PostSummary display_table={display_table} post={post} key={post._id} />
                );
            })
        );

        if(display_table === true) {
            return (<Table className="white striped responsive-table"><thead><tr style={ tableHead }><th style={ visuStyle } data-field="visu">Projet</th><th>&nbsp;</th><th style={ Object.assign(txtStyle, tableTh) } data-field="nom">Nom du projet</th><th data-field="pseudo" style={ Object.assign(smallStyle, tableTh) }>Pseudo</th><th style={ Object.assign(tableTh, txtStyle) } data-field="desc"><div className="hide-on-small-only">Descriptif</div></th><th>&nbsp;</th></tr></thead><tbody>
                { !posts || posts.length === 0 ? <EmptyMsg /> : postsList } 
            </tbody></Table>);
        } else {
            return (
                <Fragment>
                    { !posts || posts.length === 0 ? <EmptyMsg /> : postsList } 
                </Fragment>
            );
        }
    }
}
export default PostList;
