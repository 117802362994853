import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ScrollToTop from './components/commun/ScrollToTopOnMount';
import Main from './pages/Main';
import PostSingle from './pages/PostSingle';
import Posts from './pages/Posts';
import Reglement from './pages/Reglement';
import Faq from './pages/Faq';
import Mentions from './pages/Mentions';

class App extends Component {
  render() {  
      return (
        <BrowserRouter basename={ process.env.REACT_APP_BASENAME } onUpdate={() => window.scrollTo(0, 0)} >
          <ScrollToTop>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={ Main } />
                    <Route path='/p/reglement' component={ Reglement } />
                    <Route path='/p/faq' component={ Faq } />
                    <Route path='/p/mentions' component={ Mentions } />
                    <Route path='/posts' component={ Posts } />
                    <Route path='/post/:id' component={ PostSingle } />
                </Switch>
            </div>
          </ScrollToTop>
        </BrowserRouter>
      );
  }
}
export default App;
