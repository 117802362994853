import React, { Component, Fragment }       from 'react';
import { compose }                          from 'redux';
import { connect }                          from 'react-redux';
import { Row, Col }                         from 'react-materialize';

import MainPresentation                     from '../components/MainPresentation';
import MainLots                             from '../components/MainLots';
import SliderGagnants                       from '../components/commun/SliderGagnants';

import PostGagnants                         from '../components/commun/PostGagnants';
import PostList                             from '../components/commun/PostList';
import Navbar                               from '../components/commun/Navbar';
import Footer                               from '../components/commun/Footer';

class Main extends Component {
    state = {
        display_table: false
    }
    handleDisplay = (e) => { 
        this.setState({
            display_table: !this.state.display_table
        });
    }
    render() {
        const { posts } = this.props;
        return (
            <Fragment>
                <Navbar />
                <div id="app_main" className="container-fluid">
                    <Row className="m-0" >
                        <Col id="app_presentation"  s={12} className="app_screen">
                            <MainPresentation />
                        </Col>
                        
                        <Col id="app_gagnants"  s={12} className="app_screen white">
                            <div className="container">
                                <h2 className="title">La remise de prix</h2>
                                <SliderGagnants />
                                <Row className="d-flex clearfix mt-5">
                                    <PostGagnants />
                                </Row>
                            </div>
                        </Col>
                        <Col id="app_participations" s={12} className="app_screen">
                            <div id="posts__list" className="container mx-auto">
                                <h2 className="title">Sélection du jury</h2>
                                <div className="switch mb-5 right right-align ">Options d'affichage :<br/><label className="black-text">Galerie<input onClick={ this.handleDisplay } type="checkbox"/><span className="lever"></span>Tableau</label></div>
                                <Row className="d-flex clearfix mt-5">
                                    <PostList display_table={ this.state.display_table } posts={ posts } />
                                </Row>
                            </div>
                        </Col>
                        <Col id="app_lots"  s={12} className="app_screen white">
                            <MainLots />
                        </Col>
                    </Row>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    var sort_posts = state.posts.sort((a,b) => {
            return  b.votes - a.votes
    });
    var _posts = sort_posts;

    return {
        posts: _posts
    }
}
export default compose(  
    connect(mapStateToProps, null),
)(Main);
