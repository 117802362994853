import React       from 'react';
import { withRouter }               from 'react-router-dom';

import fb                           from '../../img/icons/fb.svg';

const Footer = () => {
        return(
            <footer className="page-footer hide-on-med-and-down">
                <nav className="nav-extended"> 
                    <div className="nav-content">
                        <ul>
                            <li><a href={ process.env.REACT_APP_PUBLIC_URL+'p/mentions' } className="border_grey_l ml-5">{ process.env.REACT_APP_MENTIONS_TITLE1 }</a></li>
                            <li><a href="https://elogie-siemp.paris" target="_blank" rel="noopener noreferrer"  className="small site_link color_main">elogie-siemp.paris</a></li>
                            <li>
                                <a href="https://www.facebook.com/elogiesiempidf/" target="_blank" rel="noopener noreferrer"><img width="10px" height="19px" src={ fb } alt="Facebook - Elogie-Siemp" /></a>
                            </li>
                            <li><a href="mailto:concours@elogie-siemp.paris" rel="noopener noreferrer" className="small">Vous avez d'autres questions ?</a></li>
                        </ul>
                    </div>
                </nav>

            </footer>
        );
}
export default withRouter(Footer);